* {
  box-sizing: border-box;
}

html, body {
  --font-size-h6: 12px;
  --font-size: 14px;
  --font-size-h4: 16px;
  --font-size-h3: 20px;
  --font-size-h2: 24px;
  --font-size-h1: 28px;
  --font-size-heading: 20px;
  --font-size-subheading: 12px;
  --font-family: 'halyard-display', sans-serif;
  --font-family-subheading: 'Work Sans', sans-serif;
  --color-black: #000;
  --color-black-1: #10171E;
  --color-black-2: #15202B;
  --color-black-3: #1C2938;
  --color-black-4: #38444D;
  --color-black-5: #8899A6;
  --color-silver-1: #F0F4F8;
  --color-silver-2: #D9E2EC;
  --color-silver-3: #BCCCDC;
  --color-silver-4: #9FB3C8;
  --color-silver-5: #829AB1;
  --color-primary: #E8368F;
  --color-secondary: #F7C948;
  --color-blue: #1da1f2;
  --color-green: #65D6AD;
  --color-white: #fff;
  --color-modal-overlay: rgba(0,0,0,0.7);
  --border-radius: 4px;
  --letter-spacing-subheading: 1px;
  --overlay-fade: linear-gradient(transparent, rgba(0,0,0,0.9));
  --overlay-fade-2: linear-gradient(180deg, transparent 70%, var(--color-black-1) 100%);
  --text-shadow-default: 0 1px 1px var(--color-black-1);
  --form-size: 40px;
  --screen-tablet: 720px;

  --zindex-you-were-watching: 500;
  --zindex-you-were-watching-image: 100;
  --zindex-you-were-watching-overlay: 101;
  --zindex-you-were-watching-content: 102;
  --zindex-you-were-watching-dismiss: 103;
  --zindex-player-overlay: 500;
  --zindex-player-overlay-elements: 501;
  --zindex-player-subtitles: 550;
  --zindex-player-state-buffered-indicator: 600;
  --zindex-player-volume-control: 700;
  --zindex-player-tooltip: 700;
  --zindex-modal: 1000;

  /**
   * @TODO I think I meant landscape, not portrait lol.
   */
  --player-chat-pane-width-portrait: 240px;
  --player-chat-pane-width-desktop: 320px;

  /**
   * Initial window-vh for hooks/useVhSetter.
   */
  --window-vh: 0px;

  color: var(--color-white);
  font-size: var(--font-size);
  font-family: var(--font-family);
  background: var(--color-black-1);
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size);
}

h6 {
  font-size: var(--font-size-h6);
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.ui-subheading {
  letter-spacing: 1px;
  font-family: var(--font-family-subheading);
  font-size: var(--font-size-subheading);
  text-transform: uppercase;
}

.ui-subheading.is-compact {
  letter-spacing: initial;
}

.u-text-center {
  text-align: center;
}